<template>
  <v-dialog v-model="dialog" max-width="374px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" x-small fab>
        <v-icon color="primary">mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card shaped class="pa-3">
      <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
        <v-toolbar-title>Редактировать</v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-3">
        <v-col cols="12">
          <v-text-field v-model="item.name"
                        dense
                        hide-details
                        outlined
                        rounded
                        label="Имя"/>
        </v-col>
      </v-row>
      <v-row class="justify-space-around pb-3">
        <v-btn outlined elevation="3" rounded color="error" @click="dialog = false">Отменить</v-btn>
        <v-btn outlined elevation="3" rounded color="primary" @click="save">Сохранить</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {uuid} from "uuidv4";

export default {
  props: {
   driver: Object
  },
  data() {
    return {
      item: {
        id: uuid.Nil,
        name: "",
      },
      dialog: false
    }
  },

  mounted() {
    this.item.id = this.driver.id;
    this.item.name = this.driver.name;
    this.item.active = this.driver.active;
  },

  methods: {
    save() {
      this.$store.dispatch("drivers/save", Object.assign({}, this.item));
      this.dialog = false;
    },
  }
}
</script>
