<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">Курьеры</v-toolbar-title>
    </v-toolbar>
    <v-row class="my-2 mx-5">
      <v-col cols="12">
        <v-text-field v-model="search"
                      prepend-inner-icon="mdi-magnify"
                      label="Поиск"
                      dense
                      outlined
                      rounded
                      hide-details
                      @change="fetch"/>
      </v-col>
    </v-row>
    <v-row class="ml-5 mb-2">
      <create></create>
    </v-row>
    <v-row dense class="hidden-xs-only">
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Изображение</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">ФИО</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Телефон</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Элект. адрес</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption pr-3">Статус</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Дата акт.</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption">Баланс</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption">Редактировать</span>
      </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.xs ? 'px-2' : ''" no-gutters class="ma-1 py-1 grey lighten-4" v-for="driver in list" :key="driver.id">
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1 d-flex align-center' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Фото:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <v-tooltip top>
          <template v-slot:activator="{ attrs, on }">
            <v-avatar v-bind="attrs" v-on="on" size="36px">
              <img alt="Driver photo" v-if="driver.photo" :src="driver.photo"/>
              <img alt="" v-else src="/user_avatar.png"/>
            </v-avatar>
          </template>
          <v-avatar size="96px">
            <img alt="Driver photo" v-if="driver.photo" :src="driver.photo"/>
            <img alt="" v-else src="/user_avatar.png"/>
          </v-avatar>
        </v-tooltip>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Имя:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span class="text-body-2 text-no-wrap">{{ driver.name }}</span>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Тел.:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span class="text-body-2">{{ driver.phone | normalizePhone }}</span>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Email:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span class="text-body-2 text-no-wrap">{{ driver.email }}</span>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1 d-flex align-center' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Статус:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <v-checkbox v-model="driver.active"
                    class="ma-0 pa-0"
                    hide-details
                    color="primary"
                    @change="toggle(driver)"/>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Дата акт.:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span class="text-body-2">{{ driver.updated_at | dateFormat }}</span>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Баланс:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span class="text-body-2">{{ driver.available_funds }}</span>
      </v-col>
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1 d-flex align-center' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Редактировать:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : 'px-4'" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-space-around justify-md-space-around justify-sm-space-around d-flex align-center">
        <edit :driver="driver"/>
        <refill :driver="driver"/>
        <v-btn :class="$vuetify.breakpoint.xs ? 'mx-3' : ''" x-small fab @click="vehicle(driver.id)">
          <v-icon color="blue">mdi-car</v-icon>
        </v-btn>
        <v-btn x-small fab @click="del(driver)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-pagination
        v-model="currentPage"
        :length="paginationCount"
        total-visible="9"
        @input="fetch">
    </v-pagination>
  </v-card>
</template>

<script>
import format from "date-fns/format";
import edit from "./edit.vue";
import refill from "./refill.vue";
import create from "./create.vue";


export default {
  components: {create, edit, refill},
  data() {
    return {
      search: "",
      currentPage: 1,
      limit: 12
    }
  },

  created() {
    this.fetch()
  },

  computed: {
    list() {
      return this.$store.getters["drivers/list"]
    },
    count() {
      return this.$store.getters["drivers/count"]
    },
    paginationCount() {
      if (!this.list || !this.count) {
        return 0
      }
      return Math.ceil(this.count / this.limit)
    }
  },

  methods: {
    toggle(driver) {
      this.$store.dispatch("drivers/save", driver)
      this.$store.dispatch("drivers/refill", driver)
    },
    fetch() {
      let offset = (this.currentPage - 1) * this.limit
      let payload = {offset: offset, limit: this.limit, search: this.search}
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&')
      this.$store.dispatch("drivers/fetch", queryString)
    },

    vehicle(id) {
      this.$router.push({name: 'driversVehicle', params: {id}})
    },

    document(id) {
      this.$router.push({name: 'driversDocument', params: {id}})
    },

    del(driver) {
      this.$confirm(
          "Удалить?",
          "Внимание",
          "question"
      ).then(() => {
        this.$store.dispatch("drivers/remove", driver)
      })
    }
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",")

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
    dateFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd")
    }
  }
}
</script>
