<template>
    <v-dialog v-model="dialog" max-width="374px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" x-small fab>
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card shaped class="pa-3">
        <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
          <v-toolbar-title>Пополнить счёт</v-toolbar-title>
        </v-toolbar>
        <v-row class="ma-3">
          <v-col cols="12">
            <input type="number" step="100" v-model="item.amount"
                          dense
                          hide-details
                          outlined
                          rounded
                          label="Пополнение"/>
          </v-col>
        </v-row>
        <v-row class="justify-space-around pb-3">
          <v-btn outlined elevation="3" rounded color="error" @click="dialog = false">Отменить</v-btn>
          <v-btn outlined elevation="3" rounded color="primary" @click="refill">Сохранить</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import {uuid} from "uuidv4";
  
  export default {
    props: {
     driver: Object
    },
    data() {
      return {
        item: {
          id: uuid.Nil,
          amount: 0,
        },
        dialog: false
      }
    },
  
    mounted() {
      this.item.id = this.driver.id;
      this.item.amount = this.driver.amount;
    },
  
    methods: {
      refill() {
        const amount = parseInt(this.item.amount)

        if (!isNaN(amount)) {
          this.item.amount = amount
          this.$store.dispatch("drivers/refill", Object.assign({}, this.item));
          this.dialog = false;
        } else {
            console.error('Invalid amount value. Please enter a valid number.');
        }

      },
    }
  }
  </script>
  